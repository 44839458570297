import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BackgroundImage from "gatsby-background-image"

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "course-image-2.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Testimonials" />
      <BackgroundImage
        Tag="div"
        className="bodhi__section-image-header"
        fluid={data.mainImage.childImageSharp.fluid}
      >
        <h1>Testimonials</h1>
      </BackgroundImage>
      <div
        id="service_2"
        className="course__services bodhi__bg-pattern margin-header"
      >
        <div className="container">
          <div className="row perk">
            <div className="col-sm-12 description_wrapper testimonials-wrapper">
              <div>
                <div>
                  <h5>
                    Geeta Sharma - Graduate of the NZ Diploma in ECE, Level 6
                  </h5>
                  <br />
                  <p>
                    <em>
                      "Thanks a lot for your support throughout this journey you
                      all have been so supportive and encouraged and help me
                      where I need your advice. I had great experience with you
                      all through communication via email and txt (when ever I
                      need) so thank you so much to help to complete this
                      course".
                    </em>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row perk">
            <div className="col-sm-12 description_wrapper testimonials-wrapper">
              <div>
                <div>
                  <h5>
                    Nellie Tongalea - Center Owner Pukehina Kidettes - Bay Of
                    Plenty
                  </h5>
                  <br />
                  <p>
                    <em>
                      "I would highly recommend Bodhi to anyone who is
                      considering studying Early Childhood Education. With very
                      passionate, respectful and knowledgeable tutors, students
                      are supported and mentored to become comprehensive and
                      well rounded practitioners in the care and education for
                      young children.
                    </em>
                  </p>
                  <br />
                  <p>
                    <em>
                      I have worked with students who have studied with Bodhi
                      and I can see the foundations of respect and understanding
                      for young children really coming through in their
                      practices. This seems to come naturally for students who
                      study with Bodhi because it is woven through their
                      studies".
                    </em>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row perk">
            <div className="col-sm-12 description_wrapper testimonials-wrapper">
              <div>
                <div>
                  <h5>
                    Teagan Stock - Graduate of the NZ Diploma in ECE, Level 5 &
                    6
                  </h5>
                  <br />
                  <p>
                    <em>
                      "I'm Teagan, a 27-year-old mother to a two-year-old boy,
                      studying through Bodhi has been fantastic and I did this
                      all while working in an ECE setting and caring for my
                      young baby. I have just completed level 5 and 6 over two
                      years of study through Bodhi.
                    </em>
                  </p>
                  <br />
                  <p>
                    <em>
                      I am so grateful that I chose to do my ECE education
                      through Bodhi, they were consistently supportive through
                      the entire process. I believe the content and assessments
                      were easy to follow and the learning content provided
                      through the courses was relevant and has built me into a
                      confident educator. Kim, Kerry and Mari were always
                      available and provide an effective support system to their
                      students. The small intakes made the experience much more
                      personal and made the support system more readily
                      available.
                    </em>
                  </p>
                  <br />
                  <p>
                    <em>
                      The tutors have extensive knowledge on ECE and I feel
                      privileged to be a part of the Bodhi family".
                    </em>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Testimonials
